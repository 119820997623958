@use "../../styles/variables.scss" as *;

.langDropdown {
  display: none;
  opacity: 0;

  width: 140px;
  position: absolute;
  top: 17px;
  right: -10px;
  background-color: #fff;
  text-align: end;
  border-radius: 5px;
  padding: 0 15px 0;
  z-index: -1;

  @media only screen and (max-width: $tablet1024) {
    display: block;
    opacity: 1;
    position: relative;
    top: 0;

    div {
      border-bottom: none !important;
    }
  }

  div {
    display: flex;
    justify-content: space-between;
    padding: 17px 10px;
    cursor: pointer;

    &:first-child {
      border-bottom: 0.5px solid $mid-blue;
      padding-top: 2rem;
    }
  }
}
