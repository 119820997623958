@use "../../styles/variables.scss" as *;

ul {
  @extend %reset-list;
  padding-left: 0;
}

//filter controls
.filterlist {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  @media only screen and (max-width: $tablet768) {
    gap: 10px;
  }
  @media only screen and (max-width: $mobile425) {
    flex-wrap: wrap;
  }

  li {
    @media only screen and (max-width: $mobile425) {
      width: 100vw;
    }
  }

  button {
    @extend %round-btn;

    @media only screen and (max-width: $mobile425) {
      width: 100%;
    }

    strong {
      margin-left: 0.5rem;
      font-size: 0.9em;
      font-weight: 300;
      border-radius: 1rem;
      opacity: 0.6;
    }
  }

  &.noOpacity {
    opacity: 1;
    transition: opacity 0.5s;
  }

  &.opacity {
    opacity: 0.5;
    transition: opacity 0.5s;
    pointer-events: none;
  }
}

.notActive {
  background-color: white;
  color: $black;
}
.isActive {
  color: white;
  background-color: $dark-blue;
}
.searchFilter {
  &:before {
    content: "✖";
    font-size: 0.9em;
    margin-right: 0.5rem;
  }
}
