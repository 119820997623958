@use "../styles/variables.scss" as *;

.App {
  text-align: center;
  background-color: $light-blue;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

:export {
  primaryColor: $dark-blue;
  secondaryColor: $mid-blue;
  tertiaryColor: $light-blue;
  mainFont: $font-main;
  headingSize: $main-heading;
  headingMdSize: $md-heading;
  headingSmSize: $sm-heading;
  bodyTextSize: $body-text;
  bodyTextNormalSize: $body-text-normal;
  bodyTextSmallSize: $body-text-sm;
}
