@use "../../styles/variables.scss" as *;

.contact {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: $tablet768) {
    justify-content: center;
    margin-top: 20px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 1.4rem;

    @media only screen and (max-width: $tablet768) {
      align-items: flex-start;
    }

    p,
    a {
      font-size: $body-text-normal;
    }

    a {
      color: $black;
      text-decoration: none;

      &:first-of-type {
        color: $mid-blue;
      }
    }
  }

  img {
    width: 57px;
    height: 57px;
    object-fit: cover;
    object-position: center 10%;
    border-radius: 50%;
  }
}
