@use "../../styles/variables.scss" as *;

@mixin dottPattern {
  background-image: radial-gradient(rgb(240, 241, 246) 33%, transparent 2%);
  background-size: 6px 6px;
  background-repeat: repeat-y;
  left: 1.8rem;
  width: 1rem;
  position: absolute;

  @media only screen and (max-width: $tablet768) {
    left: calc(1.8rem - $item-spacing-md);
  }
}

@mixin listCard {
  width: 100%;
  // height: 73px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: $black;
  margin: 3px 0;
  border-radius: 0.4rem;
  padding: 0.5rem 1.5rem;
  overflow: hidden;
  box-sizing: border-box;
  text-decoration: none;
  transition: all 0.3s ease;

  @media screen and (min-width: $laptop1200) {
    &:hover {
      transform: translateX(6px);
    }
  }

  @media only screen and (max-width: $tablet992) {
    height: auto;
    margin: $item-spacing-sm 0;
  }
}

.redStatus {
  color: $red-status;
}

.blueStatus {
  color: $dark-blue;
}

.orderCard {
  background-color: #ffffff95; // with 50% opacity
}

.orderCard,
.isTransport {
  @include listCard();
  position: relative;
  font-size: $body-text-normal;

  .left {
    align-items: baseline;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: $item-spacing-sm 0;

    @media only screen and (max-width: $tablet768) {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
    }

    .orderTypeIcon {
      margin-right: $item-spacing-sm;
      width: $item-spacing-md;
      text-align: center;
      position: relative;
      z-index: 0;

      @media only screen and (max-width: $tablet768) {
        width: auto;
        margin-left: auto;
        margin-right: 0;
      }
    }

    h3 {
      width: auto;
      font-weight: 400;
      //   margin-left: $item-spacing-md;
      letter-spacing: 0em;
      font-size: $body-text-normal;

      @media only screen and (max-width: $tablet768) {
        margin: $item-spacing-sm;
        width: auto;
      }
    }

    span {
      width: calc(100% / 4);
      @extend %lightText;

      @media only screen and (max-width: $tablet768) {
        width: 28%;
      }

      @media only screen and (max-width: $tablet768) {
        margin: $item-spacing-sm;
      }
    }

    .description,
    .orderId {
      @media only screen and (max-width: $tablet768) {
        width: auto;
      }
    }

    .infoContainer:first-child {
      @media only screen and (min-width: 769px) and (max-width: $tablet865) {
        width: 30%;
      }
    }
  }

  .left.bottom {
    flex-direction: row;
    justify-content: left;

    @media only screen and (max-width: $mobile425) {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
    }
  }

  .right {
    align-items: baseline;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: $item-spacing-sm 0;

    @media only screen and (max-width: $tablet768) {
      width: 100%;
      flex-direction: column;
    }

    span {
      @extend %lightText;

      @media only screen and (max-width: $tablet768) {
        width: auto;
        margin: $item-spacing-sm;
      }
    }

    .infoContainer ~ .infoContainer {
      margin-left: 0;
      margin-right: calc(1rem - 28px);
    }

    //Status
    span ~ :last-child {
      @media only screen and (max-width: $tablet992) {
        cursor: pointer;
      }

      @media only screen and (max-width: $tablet768) {
        margin-left: auto;
      }
    }
  }

  .noContent {
    display: none;
  }

  &:before {
    content: "";
    @include dottPattern();
    height: 100%;
  }
}

//Header categories for card-information
.orderCard.headerCard {
  background-color: transparent;
  height: 3rem;
  pointer-events: none;
  justify-content: left;

  @media only screen and (max-width: $tablet768) {
    display: none;
  }

  h3,
  span {
    @extend %subheading;
  }

  .left {
    .orderTypeIcon {
      margin-right: 8px;
      width: 14px;
      height: $body-text-normal;
    }
  }

  .right {
    div {
      visibility: hidden;
    }
  }
}

//mobile wrapper for content
.left,
.right {
  & span.infoContainer {
    @media only screen and (max-width: $tablet768) {
      width: 100%;
    }
  }
}

span.infoContainer {
  & ~ .infoContainer {
    margin-left: 1rem;
  }

  vertical-align: middle;
  display: inline-block;

  s {
    opacity: 0.4;
  }

  svg {
    align-self: baseline;
    margin-right: 0.3rem;
    vertical-align: middle;
    display: inline-block;
    transform: translateY(-2px);
  }

  @media only screen and (max-width: $tablet768) {
    border-bottom: 1px solid $light-grey;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: $item-spacing-sm;
    justify-content: space-between;
    margin: $item-spacing-sm 0 !important;
  }

  h4 {
    @extend %lightText;
    display: none;

    @media only screen and (max-width: $tablet768) {
      display: block;
      text-transform: uppercase;
      color: $light-blue;
      margin: $item-spacing-sm;
      margin-left: 0;
    }
  }

  h3 {
    display: inline;
  }
}

.isOngoing {
  background-color: #ffffff;
}

.isTransport {
  height: 330px;

  @media only screen and (max-width: $tablet992) {
    height: auto;
  }

  .expanded {
    margin: 0 0 $item-spacing-md $item-spacing-lg;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: $tablet992) {
      flex-direction: column;
      flex-wrap: nowrap;
      margin: $item-spacing-sm 0;
    }

    @media only screen and (max-width: $tablet768) {
      margin: 0 0 $item-spacing-sm $item-spacing-md;
    }

    .left {
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-right: $item-spacing-lg;

      @media only screen and (max-width: $tablet992) {
        width: auto;
        padding: 1rem 0;
      }

      @media only screen and (max-width: $tablet768) {
        padding: $item-spacing-sm 0;
      }

      &.bottom {
        @media only screen and (max-width: $mobile425) {
          align-items: flex-start;
        }
      }
    }

    .left .truckContainer {
      width: 100%;
      border-bottom: 2px solid $light-grey;
      position: relative;
      padding-top: 2.5rem;

      @media only screen and (max-width: $tablet768) {
        display: none;
        // padding-top: 4rem;
        // margin-bottom: $item-spacing-sm;
      }
    }

    .left .info:nth-child(3) {
      text-align: right;

      @media only screen and (max-width: $mobile425) {
        text-align: left;
      }
    }

    .left .info {
      @media only screen and (max-width: $tablet992) {
        margin-top: 30px;
      }

      @media only screen and (max-width: $mobile425) {
        width: 122px;
      }

      h4 {
        @extend %lightText;
        color: $mid-blue;
        text-transform: uppercase;
        font-size: $body-text-sm;
        margin-bottom: $item-spacing-sm;
      }

      .large {
        @extend %lightText;
        font-size: $sm-heading;
        margin-bottom: $item-spacing-sm;
      }

      .address {
        @extend %lightText;
        font-size: $body-text-normal;
      }
    }

    .left .info:nth-child(2) {
      @media only screen and (max-width: $mobile425) {
        margin-top: 0;
      }
    }

    .right {
      background-color: $mid-blue;
      height: 235px;
      width: 45%;
      border-radius: 0.4rem;

      @media only screen and (max-width: $tablet992) {
        width: auto;
        margin: 0.5rem 0;
      }
    }
  }
}

.first {
  &:before {
    height: 50%;
    bottom: 0;
  }
}

.last {
  &:before {
    height: 50%;
    top: 0;
  }
}

// and is first
.first.isOngoing {
  &:before {
    height: calc(73px / 2);
    bottom: 0;
  }
}

//is only item in list
li .orderCard.first.last {
  &:before {
    height: 0;
  }
}

//is only item in list is transport
li .orderCard.isTransport.first.last.isOngoing {
  &:before {
    height: 0;
  }
}

// and is last
.last.isOngoing.isTransport {
  &:before {
    height: calc(73px / 2);
    top: 0;
  }
}
