@use "../../styles/variables.scss" as *;

.layoutContainer {
  header {
    display: flex;
    align-items: center;

    nav {
      margin-left: 1rem;
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 1rem;
      color: $mid-blue;

      ul {
        @extend %reset-list;

        li {
          a {
            color: $mid-blue;
            text-decoration: none;

            &.active {
              color: $dark-blue;
            }
          }
        }
      }

      ul.toolMenu {
        margin-left: auto;
      }
    }
  }
}

.pageWrapper {
  min-height: calc(100vh - 210px);
  max-width: calc(1115px + 5rem);
  padding: 5rem 1rem;
  margin: auto;
  box-sizing: border-box;

  @media screen and (min-width: $laptop1200) {
    padding: 5rem 2rem;
  }

  &.fullWidth {
    max-width: none;
    padding: 0;
  }

  .docListContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    gap: 30px;

    @media screen and (max-width: $tablet992) {
      flex-direction: column;
    }

    & > div {
      max-width: 50%;

      @media screen and (max-width: $tablet992) {
        max-width: 100%;
      }
    }

    div {
      width: 100%;

      h2 {
        font-weight: 500;
      }
    }
  }
}
