@use "../../styles/variables.scss" as *;
// move headings to global

h2 {
  margin-top: 1rem !important;
  color: $black;
}
.row {
  display: flex;

  @media only screen and (max-width: $tablet992) {
    flex-direction: column;
  }
}

ul {
  @extend %reset-list;
  padding-left: 0;
}

.container {
  margin: 0;
  padding-left: 0;

  &.noOpacity {
    opacity: 1;
    transition: opacity 0.5s;
  }

  &.opacity {
    opacity: 0.5;
    transition: opacity 0.5s;
    pointer-events: none;
  }
  ul {
    padding-left: 0;
  }

  .noResult {
    @media only screen and (max-width: $tablet768) {
      max-width: 53%;
    }
  }
}

//sort controls
.sortDates {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translateY(calc(100% + 1.5rem));
  select {
    cursor: pointer;
    font-size: $body-text-normal;
    color: $mid-blue;
    background-color: transparent;
    border: none;
    font-weight: 300;
  }
}
