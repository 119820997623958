@use "../../styles/variables.scss" as *;

.listTabContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  @media only screen and (max-width: $tablet768) {
    flex-direction: column-reverse;
  }
  & > div {
    flex: calc(50% - 1rem);
    max-width: calc(50% - 1rem);
    @media only screen and (max-width: $tablet768) {
      flex: 100%;
      max-width: 100%;
    }
    & > div {
      margin-bottom: 2rem;
      @media only screen and (max-width: $tablet768) {
        margin-bottom: 0rem;
      }
    }
  }
}
