@use "../../styles/variables.scss" as *;

.asc,
.desc {
  margin-bottom: 2rem;
}

.asc {
  flex-direction: column-reverse;
}
.desc {
  flex-direction: column-reverse;
}
h2 {
  margin-bottom: 0.8rem !important;
}
.loadMore {
  @extend %round-btn;
  background-color: white;
  color: $dark-blue;
  margin: 0 auto;
  margin-top: 20px;
}
li.empty {
  background-color: #ffffff;
  color: $mid-blue;
  border-radius: 0.4rem;
  font-weight: 200;
  padding: 1.3rem 2rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}
