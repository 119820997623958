@use "../../styles/variables.scss" as *;

.orderDetailsInfo {
  width: 100%;
  display: flex;
  margin-bottom: 50px;

  @media only screen and (max-width: $tablet768) {
    flex-wrap: wrap;
    gap: 20px 0;
  }

  p {
    &:first-of-type {
      &:not(:last-child) {
        color: $light-blue;
        font-size: $body-text-sm;
        text-transform: uppercase;
      }
    }
    &:last-of-type {
      color: $black;
      font-size: $body-text;
      font-weight: 300;
    }
  }

  div {
    min-width: 6%;

    &:not(:last-child) {
      margin-right: 30px;

      @media only screen and (max-width: $tablet768) {
        margin-right: 20px;
      }
    }

    &:last-child {
      min-width: 6%;
      border-radius: 100px;

      p {
        color: #fff;
        font-size: $body-text-normal;
        line-height: 20px;
      }
    }
  }
}
