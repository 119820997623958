@use "../../styles/variables.scss" as *;

.order-events__container {
  width: 100%;
  border-spacing: 0 2px;
  th {
    border-collapse: collapse;

    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 10px;

    text-align: left;
    color: $light-blue;
    font-size: $body-text-sm;
    text-transform: uppercase;
    font-weight: 400;
  }

  td {
    padding: 19px 2%;
  }

  tbody {
    background: #ffffff;

    tr {
      vertical-align: top;
    }
  }

  td {
    color: #282b37;
    font-family: "Roobert";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;

    letter-spacing: -0.04em;

    &:first-child,
    &:last-child {
      --border-radius: 4px;

      border-color: transparent;
      border-left-style: solid;
    }

    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }

    &:last-child {
      border-bottom-right-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }
  }

  .image-event {
    display: inline-flex;
    align-items: center;
    gap: 7px;

    &::before {
      content: url("../../assets/images/camera.svg");
      line-height: 0;
    }
  }

  .notes {
    overflow: auto;

    &::after {
      content: "";
      clear: both;
      display: table;
    }

    img {
      border-radius: 4px;
      max-height: 133px;
      object-fit: cover;
      object-position: center;
      float: right;
    }
  }
}
