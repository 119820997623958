@use "../../styles/variables.scss" as *;

.link {
  color: $dark-blue;
}

.smallArrow {
  transform: rotate(180deg);
  margin-right: $item-spacing-sm;
}
