@use "../../styles/variables.scss" as *;

.upload {
  display: flex;
  margin-top: 10px;
  cursor: pointer;

  .uploadCloud {
    transform: translateY(3px);
    transition: all 0.2s ease;
  }

  &:hover {
    .uploadCloud {
      transform: translateY(1px);
    }
  }

  p {
    color: $dark-blue;
    font-size: $body-text-normal;
    font-weight: 600;
    margin-left: 5px;
  }
}

.uploadForm {
  background-color: #fff;
  border-radius: 0.4rem;
  padding: 1.2rem 2rem;
  box-sizing: border-box;
  margin-top: 1rem;

  h3 {
    margin-top: 1rem;
  }

  .uploadCloud {
    transform: scale(1.5);
  }

  form {
    padding: 2rem 0rem;
    display: flex;
    flex-direction: column;

    input {
      margin-top: 1.4rem;
      margin-bottom: 1.4rem;
    }

    input[type="text"] {
      margin-top: 0rem;
      margin-bottom: 0rem;
    }
  }
}
