@use "../../styles/variables.scss" as *;

.fileListContainer {
  max-width: 100%;

  p {
    color: $black;
    font-size: $body-text-normal;
  }

  .document {
    min-width: 1rem;
  }

  .file {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    a {
      text-align: start;
      color: $black;
      font-size: $body-text-normal;
      font-weight: 400;

      white-space: pre-wrap; /* Wrap long words */
      word-wrap: break-word; /* Break words that are too long */
      overflow-wrap: break-word; /* Break words to prevent overflow */
      overflow: hidden; /* Hide any overflow content */
      text-overflow: ellipsis; /* Add ellipsis (...) to indicate overflow */

      &:hover {
        text-decoration: underline;
      }
    }
  }

  td {
    min-width: 12ch;
  }
}
