@use "../../styles/variables.scss" as *;

.notificationsDropdown {
  width: 330px;
  display: none;
  opacity: 0;
  position: absolute;
  top: -10px;
  right: -14px;
  background-color: rgba(255, 255, 255);
  text-align: end;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 0 15px 0;
  z-index: 9; // to show below notificationMenuItem
  padding-bottom: 1rem;

  @media only screen and (max-width: $tablet1024) {
    display: block;
    opacity: 1;
    position: relative;
    top: 0;
    right: 0;
  }

  .header {
    display: flex;
    width: 100%;
    flex-direction: row;
    text-align: left;
    padding-top: 0.7rem;
    padding-bottom: 1rem;

    button {
      color: $dark-blue;
      font-family: $font-main;
      cursor: pointer;
      border: 0;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid $dark-blue;
      }

      &:before {
        content: "";
        margin: 0;
        display: inline-block;
        width: 8px;
        height: 3px;
        border: 2px solid $dark-blue;
        margin-right: 0px;
        border-top: 0;
        border-right: 0;
        transform: rotate(-45deg) translateY(-5px);
      }

      background: none;
      font-size: 0.86rem;

      padding-left: 0;
      padding-top: 0;
      text-align: left;
    }

    p {
      width: 80%;
      margin-right: auto;
    }
  }

  .notification {
    display: flex;
    justify-content: space-between;
    padding: 17px 10px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: rgba(67, 124, 142, 0.05);
    }

    &:first-child {
      border-bottom: 0.5px solid $mid-blue;
      padding-top: 2rem;

      @media only screen and (max-width: $tablet1024) {
        border: none;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: start;

      &.spec {
        position: relative;
        margin-left: 1.5rem;

        span {
          font-size: 0.9em;
        }

        &:before {
          content: "";
          width: 10px;
          height: 10px;
          display: block;
          position: absolute;
          top: 49%;
          left: -1.5rem;
          transform: translateY(-50%);
          background-color: $green-status;
          border-radius: 50%;
        }

        p {
          color: $black;
          font-size: 1rem;
        }
      }
    }

    &.Delayed {
      div.spec {
        &:before {
          background-color: $red-status;
        }
      }
    }

    &.Changed {
      div.spec {
        &:before {
          background-color: $dark-blue;
        }
      }
    }
  }
}
