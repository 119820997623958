@use "./variables.scss" as *;
@use "./typography.scss" as *;

html {
  background-color: $light-grey !important;
  font-family: $font-main;
  -webkit-font-smoothing: antialiased;

  @for $i from 2 through 6 {
    h#{$i} {
      @extend %reset-fonts;
      letter-spacing: 0.03em;
    }
  }

  p {
    @extend %reset-fonts;
  }

  select,
  input,
  textarea {
    font-family: $font-main;
  }
}

a {
  text-decoration: none;
}

body {
  margin: 0;
}

.back-button {
  width: fit-content;
  color: $mid-blue;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  &:before {
    margin-right: 0.3rem;
    display: inline-block;
    content: "";
    height: 0.4em;
    width: 0.4em;
    border: 2px solid $mid-blue;
    border-top: 0;
    border-right: 0;
    transform: rotate(45deg);
    transition: all 0.2s ease;
  }

  &:hover {
    &:before {
      margin-right: 0.5rem;
    }
  }
}

.sub-heading,
.breadcrumb > a {
  color: $dark-blue;
  font-size: 1rem;
  text-decoration: none;
}

.notice {
  margin-top: 1rem;
  margin-bottom: 1rem;

  animation: noticeAnimation 0s ease-in 5s forwards;

  &.success {
    color: hsl(120, 100%, 25%);
  }

  &.error {
    color: hsl(339, 100%, 25%);
  }
}

@keyframes noticeAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

@-webkit-keyframes noticeAnimation {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}

// Spinner icon
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  display: flex;
  align-self: center;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid $mid-blue;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $mid-blue transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// this specific for
.delete-file-icon {
  cursor: pointer;
  margin-left: 1rem;
}

.word-wrap {
  white-space: pre-wrap; /* Wrap long words */
  word-wrap: break-word; /* Break words that are too long */
  overflow-wrap: break-word; /* Break words to prevent overflow */
  overflow: hidden; /* Hide any overflow content */
  text-overflow: ellipsis; /* Add ellipsis (...) to indicate overflow */
}

td {
  min-width: 10ch;
}

.centered {
  text-align: center;
}
