@use "../../styles/variables.scss" as *;

header {
  --menu-height: 74px;

  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: #fff;
  padding: 0.6rem 2rem;

  .logo {
    height: 3.6rem;
  }

  nav {
    width: 100%;
    display: flex;
    align-items: center;
    color: $mid-blue;
    margin-left: 1rem;
    padding-left: 1rem;

    ul {
      display: flex;
      flex-direction: row;
      @extend %reset-list;

      li {
        &:not(:last-child) {
          margin-right: 2rem;
        }

        a {
          color: $mid-blue;
          font-size: $body-text-normal;
          font-weight: 400;
          text-decoration: none;
          &.active {
            color: $dark-blue;
          }
        }
      }
    }

    ul.mainMenu {
      display: flex;
      flex-direction: row;

      .active {
        color: $dark-blue;
      }

      .notActive {
        color: $mid-blue;
      }
    }

    ul.toolMenu {
      position: relative;
      z-index: 0;
      margin-left: auto !important;

      @media only screen and (max-width: $tablet1024) {
        width: 100vw;
        min-height: 100vh;

        display: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        background-color: #fff;
        box-shadow: 0 7px 7px -1px rgba(0, 0, 0, 0.14);
        top: var(--menu-height);
        right: 0;

        &.open {
          display: flex;
        }

        li {
          font-size: 18px !important;
          text-transform: uppercase;
          text-align: center;
          margin: 1rem 0;

          &:nth-child(2) {
            order: 1;
          }

          div {
            font-size: $body-text-normal;
            text-transform: none;
          }
        }
      }

      li {
        color: $mid-blue;
        font-size: $body-text-normal;
        font-weight: 400;
        cursor: pointer;

        &.language {
          @media only screen and (min-width: $tablet1024) {
            &:hover {
              & > div {
                display: block;
                opacity: 1;
                animation: showMenu 0.3s linear 0.01s forwards;
              }
            }
          }
        }

        .user {
          margin: 0 5px -4px 0;
        }

        .arrow {
          margin: 0 0 1px 5px;
        }

        button {
          color: $mid-blue;
          text-transform: capitalize;
        }
      }
    }

    .burger {
      display: none;

      @media only screen and (max-width: $tablet1024) {
        width: 25px;
        height: 15px;
        display: block;
        position: absolute;
        right: 2rem;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $dark-blue;
          border-radius: 5px;

          &:nth-child(1) {
            top: 10%;

            &.change {
              transform: rotate(45deg);
              top: 50%;
            }
          }

          &:nth-child(2) {
            top: 50%;

            &.change {
              display: none;
            }
          }

          &:nth-child(3) {
            top: 90%;

            &.change {
              transform: rotate(-45deg);
              top: 50%;
            }
          }
        }
      }
    }
  }
}
