@use "../../styles/variables.scss" as *;

// $truckPos: 0;
:root {
  --truckPos: 0;
  --linePos: 23%;
}

.truck {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: var(--truckPos); // manipulate using js
  width: 12%;
  height: auto;
  @media only screen and (max-width: $tablet768) {
    // transform: scale(0.7);
  }
}
hr {
  //default truck width
  width: var(--linePos); // manipulate using js
  margin: 0;
  border: none;
  background-color: $dark-blue;
  height: 2px;
  position: absolute;
}
