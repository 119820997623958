@font-face {
  font-family: "Roobert";
  src:
    local("Roobert"),
    url("../assets/fonts/WEB/Roobert-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roobert";
  src:
    local("Roobert"),
    url("../assets/fonts/WEB/Roobert-Medium.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
