@use "../../styles/variables" as *;

.table-list {
  width: 100%;
  overflow-x: auto;

  .load-more {
    @extend %round-btn;
    background-color: white;
    color: $dark-blue;
    margin: 0 auto;
    margin-top: 20px;
  }
}

// table element
.table-list__container {
  width: 100%;
  border-spacing: 0 3px;

  th {
    border-collapse: collapse;
    padding: 0.5rem 1rem;
    text-align: left;
    color: $light-blue;
    font-size: $body-text-sm;
    text-transform: uppercase;
    font-weight: 400;
  }

  td {
    padding: 1.2rem 1rem;
    strong {
      font-weight: 500;
    }
  }

  tbody {
    background: #ffffff;
    tr {
      vertical-align: top;
    }
  }

  td {
    min-width: 16ch !important;

    color: #282b37;
    font-weight: 300;
    font-size: 0.88rem;
    vertical-align: middle;
    &:first-child,
    &:last-child {
      --border-radius: 6px;

      border-color: transparent;
      border-left-style: solid;
    }

    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }

    &:last-child {
      border-bottom-right-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);

      min-width: 10ch !important;
    }

    .td-icon {
      display: inline-block;
      margin-left: -3px;
      margin-right: 5px;
      transform: translateY(13%);
      font-size: 14px;
      margin-top: -10%;
    }
  }

  .image-event {
    display: inline-flex;
    align-items: center;
    gap: 7px;

    &::before {
      content: url("../../assets/images/camera.svg");
      line-height: 0;
    }
  }

  .notes {
    overflow: auto;

    &::after {
      content: "";
      clear: both;
      display: table;
    }

    img {
      border-radius: 4px;
      max-height: 133px;
      object-fit: cover;
      object-position: center;
      float: right;
    }
  }

  .no-found {
    font-size: 16px;
    font-weight: 400;
  }
}
