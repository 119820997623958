@use "../../styles/variables.scss" as *;

.orderStatus {
  padding: 0.4rem 0;
  @extend %round-btn;

  &.notifyStatusBar {
    width: 65.19px;
  }

  &.otherStatusBar {
    width: 100.77px;
  }

  &.onTime {
    background-color: $green-status;
  }

  &.changed {
    background-color: $dark-blue;
  }

  &.delay {
    background-color: $red-status;
  }

  &.invoiced,
  &.completed {
    background-color: rgb(221, 222, 225);
  }

  &.hide {
    visibility: hidden;
    // width: 0;
  }

  &:hover {
    opacity: 1;
  }

  p {
    color: #ffffff;
  }

  @media only screen and (max-width: $tablet768) {
    width: 25%;
    text-align: center;
    padding: 0.4rem 1.1rem;
    max-width: 60px;
  }
}

.hasOderEvents {
  position: relative;

  &::before {
    visibility: visible;
    position: absolute;
    left: calc(-40px);
    content: url("../../assets/images/camera.svg");
    line-height: 0;
  }
}
