@use "../../styles/variables.scss" as *;

.search {
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  gap: 16px;
  box-sizing: border-box;
  margin-left: auto;

  @media only screen and (max-width: $tablet992) {
    width: 100%;
    max-width: 409px;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: unset;
  }

  @media only screen and (max-width: $tablet768) {
    max-width: 395px;
  }

  @media only screen and (max-width: $mobile425) {
    max-width: 393px;
  }

  input {
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border: 1px solid $light-blue;
    border-radius: 4px;
    font-size: $body-text-normal;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-left: 2%;

    &::placeholder {
      font-weight: 100;
    }

    &:focus {
      border: 1px solid $dark-blue;

      &::placeholder {
        color: transparent;
      }
    }
  }

  .searchIcon,
  .closeIcon {
    position: absolute;
    top: 55%;
    right: 15px;
    transform: translateY(-50%);

    @media only screen and (max-width: $tablet992) {
      top: 50%;
      right: 3.5%;
      transform: translateY(-50%) scale(0.8);
    }

    &:not(.searchIcon) {
      cursor: pointer;

      @media only screen and (max-width: $tablet768) {
        right: 6.8%;
      }
    }
  }
}
