@use "../../styles/variables.scss" as *;

footer {
  height: 210px;
  position: relative;
  background-color: #fff;
  padding: 0;

  @media only screen and (max-width: $tablet768) {
    height: 240px;
  }

  @media only screen and (max-width: $laptop1200) {
    padding: 0 1rem;
  }

  &:after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    top: 65%;
    left: 0;
    background-color: $light-blue;

    @media only screen and (max-width: $tablet768) {
      top: 80%;
    }
  }

  .top,
  .bottom {
    max-width: $page-width;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }

  .top {
    padding: 45px 0;

    @media only screen and (max-width: $tablet768) {
      flex-direction: column;
      align-items: center;

      h2 {
        margin-top: 0 !important;
      }
    }
  }

  .bottom {
    padding-top: 15px;

    p,
    a {
      color: $mid-blue;
      font-size: $body-text-normal;
      text-decoration: none;
    }
  }
}
