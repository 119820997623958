@use "../../styles/variables.scss" as *;

.notifications {
  position: relative;

  &.hasNotifications {
    &:before {
      content: "";
      width: 13px;
      height: 13px;
      display: block;
      position: absolute;
      top: -3px;
      left: -4px;
      background-color: $red-status;
      border-radius: 50%;

      @media only screen and (max-width: $tablet1024) {
        display: none;
      }
    }
  }

  span.count {
    position: absolute;
    top: -6px;
    left: 6px;
    color: #fff;
    background-color: $red-status;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.69rem;
    border-radius: 50%;
    transform: scale(0.98);
  }

  &.noNotifications {
    &:before {
      content: "";
    }
  }

  @media only screen and (min-width: $tablet1024) {
    &:hover {
      & > div {
        display: block;
        animation: showMenu 0.3s linear 0.01s forwards;
      }
    }
  }
}

.notificationMenuItem {
  position: relative;
  z-index: 10;
}

.clock {
  margin: 0 5px -3px 0;
}

@keyframes showMenu {
  0% {
    top: -50px;
    opacity: 0;
  }
  10% {
    top: 0;
  }
  100% {
    opacity: 1;
  }
}
