@use "../../styles/variables.scss" as *;

.userDetailsContainer {
  width: 100%;

  .userDetails {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    padding: 0.1rem 0;

    li {
      width: 95%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding: 1rem 0;

      &:not(:last-child) {
        border-bottom: 1px solid $light-blue;
      }

      a {
        color: inherit;
        text-decoration: underline;
        margin-left: 10px;
      }

      p {
        color: $mid-blue;
        font-size: $body-text-normal;

        &:last-child {
          color: $black;
        }
      }
    }
  }

  .smallArrow {
    margin-right: $item-spacing-sm;
  }
}

:export {
  spacing: $item-spacing-lg;
}
