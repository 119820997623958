@use "../../styles/variables.scss" as *;

.mapWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > div {
    height: calc(100% + 20px);
  }

  &.disable {
    pointer-events: none;
  }
}
