@use "../../styles/variables.scss" as *;

.container {
  margin: auto;
  max-width: 50ch;
  margin-top: 1rem;
  button {
    margin-top: 2rem;
  }
}
