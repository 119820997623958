@use "../../styles/variables.scss" as *;

.loginForm,
.verificationForm {
  margin: auto;
  max-width: 50ch;

  div:nth-child(1),
  div:nth-child(2) {
    background-color: transparent;

    input {
      background-color: #fff;
    }

    p {
      font-size: $body-text-normal;
      margin: 0 auto;
      margin-top: $item-spacing-lg;
    }
  }

  .infoMsg {
    @extend %lightText;
    text-align: center;
    color: $mid-blue;

    a {
      @extend %link;
    }
  }
}

:export {
  spacing: $item-spacing-lg;
}
