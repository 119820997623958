@use "../../styles/variables.scss" as *;

.projectCard {
  width: 100%;
  height: 103px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: $black;
  margin: 3px 0;
  border-radius: 0.4rem;
  overflow: hidden;
  box-sizing: border-box;
  text-decoration: none;
  transition: all 0.3s ease;
  @media screen and (min-width: $laptop1200) {
    &:hover {
      transform: translateX(6px);
    }
  }

  @media only screen and (max-width: $tablet992) {
    height: auto;
    flex-direction: column;
    margin: 8px 0;
  }

  .map {
    width: 0;
    min-width: 0;
    max-width: 0;
    height: 100%;
    @media only screen and (max-width: $tablet992) {
      width: 100%;
      max-width: 100%;
      height: 20vh;
    }
    @media only screen and (max-width: $mobile375) {
    }
  }

  .content {
    width: 95%;
    max-width: 95%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    box-sizing: border-box;
    @media only screen and (max-width: $tablet992) {
      width: 90%;
      max-width: 90%;
      height: auto;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      text-align: start;
      margin: 0;
      padding-left: 0;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    @media only screen and (max-width: $mobile375) {
      height: 65%;
    }
  }

  .left {
    width: 47%;
    max-width: 47%;
    text-align: start;
    @media only screen and (max-width: $tablet992) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 1rem;
    }

    h3 {
      width: 94.5%;
      font-size: $md-heading;
      font-weight: 400;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @media only screen and (max-width: $tablet768) {
        width: 100%;
        display: block;
      }
    }

    p {
      color: $mid-blue;
      font-size: $body-text-normal;
      font-weight: 300;
    }
  }

  .right {
    width: 53%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @extend %reset-list;
    @media only screen and (max-width: $tablet992) {
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    h4 {
      color: $mid-blue;
      font-size: $body-text-sm;
      text-transform: uppercase;
      font-weight: 300;
      @media only screen and (max-width: 382px) {
        margin-top: 10px;
      }
    }

    p {
      font-size: $body-text-normal;

      &.delayClass {
        color: $red-status;
      }

      &.changedClass {
        color: $light-blue;
        text-decoration: line-through;
      }
    }

    li:not(:last-child) {
      text-align: start;

      @media only screen and (max-width: $tablet768) {
        text-align: start;
      }

      @media only screen and (max-width: $tablet992) {
        margin-right: 5%;
      }

      p {
        font-size: $body-text;
      }
    }

    li {
      &.hide {
        visibility: hidden;
        @media only screen and (max-width: $tablet992) {
          display: none;
        }
      }
    }

    li:first-child {
      @media only screen and (max-width: $tablet768) {
        margin: 0;
        margin-right: 5%;
      }
    }

    li:last-child {
      @media only screen and (max-width: $tablet768) {
        width: 100%;
        margin-top: 20px;
      }

      @media only screen and (max-width: $mobile425) {
        margin-bottom: 5px;
      }
    }
  }

  .arrow {
    width: 5%;
    min-width: 5%;
    max-width: 5%;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: $tablet992) {
      display: none;
    }

    &::before {
      content: url("data:image/svg+xml, %3Csvg width='6' height='14' viewBox='0 0 6 14' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M1 1L4.93378 5.71227C5.55167 6.45244 5.55352 7.52809 4.93817 8.27038L1.01739 13' stroke='%23002C8B' /%3E%3C/svg%3E");
    }
  }
}
