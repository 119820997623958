@use "../../styles/variables.scss" as *;

.login-split-container {
  display: flex;
  min-height: calc(100vh - 83px);
  width: 100%;
  // margin-top: -6rem;
}

.login-single-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
}

.login-left-content {
  flex: 0.67;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  align-items: center;
  background-color: #fff;
  margin: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  background-image: url("../../assets/images/login-screen.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  a {
    color: #fff;
    text-decoration: underline;

    &:hover {
      color: $light-blue;
    }
  }

  // Text content wrapper
  .text-content {
    position: relative;
    z-index: 1;
    padding-bottom: 1rem;
    text-align: center;
    width: 100%;

    h2 {
      font-size: 48px;
      font-weight: 400;
      color: #fff;
      letter-spacing: 0em;
      line-height: 1;
    }

    p {
      margin: auto;
    }
  }

  // Modify the overlay to use a gradient
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    background: linear-gradient(
      to top,
      rgba(23, 35, 68, 0.8) 0%,
      rgba(23, 35, 68, 0.6) 30%,
      rgba(23, 35, 68, 0.1) 50%,
      rgba(23, 35, 68, 0) 100%
    );
  }

  // Make sure content stays above the overlay
  h1,
  p,
  h2,
  a {
    position: relative;
    z-index: 1;
    line-height: 1.5;
  }

  h1 {
    margin-bottom: 2rem;
    color: #fff;
  }

  a.button {
    color: #fff;
    text-decoration: none;
    margin-bottom: 2rem;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0.4rem 1.1rem;
    border-radius: 1rem;
    font-size: 0.9rem;
    font-family: $font-main;
    letter-spacing: 0.01em;

    &:hover {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  a.back-to-website {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #fff;
    max-width: 500px;
  }
}

.login-right-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

// Media query for mobile devices
@media (max-width: 768px) {
  .login-split-container {
    flex-direction: column;
  }

  .login-left-content {
    padding: 2rem;
    text-align: center;
    min-height: 40vh;

    p {
      margin: 0 auto;
    }
  }

  .login-right-content {
    padding: 1rem;
  }
}
