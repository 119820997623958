@use "../../styles/variables.scss" as *;

h3 {
  font-size: $md-heading;
}

.detailListContainer {
  width: 100%;

  @media only screen and (max-width: $tablet768) {
    width: 100%;

    &:not(:first-child) {
      margin-top: 32px;
    }
  }
}

.detailList {
  width: 100%;
  background-color: #fff;
  border-radius: 0.4rem;
  margin-top: 10px;

  li {
    display: flex;
    justify-content: space-between;
    padding: 19px 0;
    margin: 0 16px;

    @media only screen and (max-width: $tablet768) {
      flex-direction: column;
      justify-content: center;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $light-blue;
    }

    p {
      color: $black;
      font-size: $body-text-normal;
      text-align: end;

      @media only screen and (max-width: $tablet768) {
        text-align: start;
      }

      &:last-child {
        max-width: 70%;
        font-weight: 100;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media only screen and (max-width: $tablet768) {
          max-width: 100%;
          margin-top: 3px;
          overflow: visible;
          white-space: unset;
          word-break: break-all;
        }
      }
    }
  }
}
