.set-password-container {
  margin: auto;
  max-width: 50ch;

  form {
    margin-top: 2rem;

    button {
      svg {
        margin-right: 10px;
      }
    }
  }
}
