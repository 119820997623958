//Root variables
:root {
  --border-color: #002c8b;
  --border-width: 1px;
  --bottom-distance: 0px;
}

//Colors
$dark-blue: #002c8b;
$mid-blue: #6e7699;
$light-blue: #c0c6d9;
$light-grey: #f0f1f6;

$black: #282b37;
$red-status: #e9635a;
$green-status: #6ebc79;

//Fonts
$main-heading: 48px;
$md-heading: 24px;
$sm-heading: 24px;
$body-text: 18px;
$body-text-normal: 14px;
$body-text-sm: 12px;

$font-main: "Roobert", Arial, sans-serif;

//Spacings
$item-spacing-sm: 8px;
$item-spacing-md: 16px;
$item-spacing-lg: 30px;

//Breakpoints
$mobile320: 320px;
$mobile375: 375px;
$mobile425: 425px;
$tablet768: 768px;
$tablet865: 865px; //custom Breakpoint
$tablet992: 992px;
$tablet1024: 1024px;
$laptop1200: 1200px;
$laptop1440: 1440px;
$desktop1600: 1600px;
$desktop2560: 2560px;

//Widths
$page-width: 1120px;

//Extended variables
%reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

%reset-fonts {
  line-height: normal;
  font-weight: 400;
  margin: 0;
}

$page-width: 1120px;

%round-btn {
  cursor: pointer;
  border: none;
  text-align: center;
  padding: 0.4rem 1.1rem;
  border-radius: 1rem;
  font-size: 0.9rem;
  font-family: $font-main;
  font-weight: 300;
  letter-spacing: 0.01em;
  p {
    font-size: 0.9rem;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.2s background-color;
  @media screen and (min-width: $laptop1200) {
    &:hover {
      opacity: 0.8;
      transition: ease-in-out 0.3s opacity;
    }
  }
}

%link {
  cursor: pointer;
  text-decoration: none;
  color: $dark-blue;

  background-image: linear-gradient(var(--border-color), var(--border-color));
  background-size: 0% var(--border-width);
  background-position: bottom left;
  background-repeat: no-repeat;
  transition: background-size 0.3s;

  &:hover {
    background-size: 100% var(--border-width);
  }
}

%reset-fonts {
  line-height: normal;
  font-weight: 400;
  margin: 0;
}

%subheading {
  color: $mid-blue;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 300;
}

%lightText {
  font-weight: 300;
}
